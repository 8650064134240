import { axiosTNL } from "./axiosInstance";

export default {
    async processLogin(loginform) {
        return axiosTNL.post("order/ValidateDealerLogin", loginform);
    },
    async processAdminLogin(loginform) {
        return axiosTNL.post("order/ValidateTNLLogin", loginform);
    },
    async updateAdminLogin(loginform) {
        return axiosTNL.post("order/UpdateTNLLogin", loginform);
    }
};
